/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import CookieConsent from "react-cookie-consent";

import Header from './header'
import './layout.css'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import styled from "styled-components"

const theme = createMuiTheme({
  palette:
  {
    primary: { 500: '#16232c' }
  }
})

const CopyrightContainer = styled.div`
  margin-top: 8px;
  font-size: 0.7rem;
  text-align: right;
`

const Footer = styled.footer`
  font-weight: 700;
  font-size: 1.5rem;
  font-family: "NiveauRegular", Arial, Helvetica, sans-serif;
  color: #fff;
  background-color: #16232c;
  padding: 25px 3px 0 0;
  text-align: center;

  @media (max-width: 768px) {
      font-size: 1.3rem;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <MuiThemeProvider theme={theme}>
          <main>{children}</main>
        </MuiThemeProvider>
      </div>
      <Footer>
        <div>NO JOB TOO LARGE OR TOO SMALL!!!</div>
        <CopyrightContainer>&copy; {new Date().getFullYear()} LH Building and Maintainence</CopyrightContainer>
      </Footer>
      <CookieConsent
        location="bottom"
        buttonText="Ok, understood"
        style={{ background: '#16232c' }}
        buttonStyle={{
          color: '#fff',
          backgroundColor: '#4c5e6f'
        }}
        expires={365}
        cookieName="lh-bandm-cookies">
        We use cookies and other tracking technologies to improve your browsing experience on our website, to analyze our website traffic, and to understand where our visitors are coming from.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
