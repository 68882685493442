import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utilities/font-awesome';

const SocialContainer = styled.div`
  height: 50px;
  float: right;
  display: flex;
  align-items: center;

  a{
    padding-left: 10px;
    align-items: center;
    display: flex;
  }
`

const LogoContainer = styled.div`
  display: inline-block;
  cursor: pointer;
`


const handleClick = () => {
  navigate('/');
}

const Header = ({ siteTitle }) => (
  <header
    style={{
      backgroundColor: `#16232c`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1rem`,
      }}
    >
      <LogoContainer onClick={handleClick} onKeyDown={handleClick}  role="button" tabIndex={0}>
        <StaticImage
          src="../images/logo-white-small.png"
          alt="LH Building and Services"
          height={50} />
      </LogoContainer>
      <SocialContainer>
        <a className='icons' href='https://www.facebook.com/lhbuildingandmaintenance'>
          <FontAwesomeIcon color="#fff" size="2x" icon={['fab', 'facebook-square']} />
        </a>
        <a className='icons' href='https://www.instagram.com/lhbandm/'>
          <FontAwesomeIcon color="#fff" size="2x" icon={['fab', 'instagram']} />
        </a>
      </SocialContainer>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
